<template>
    <div>
        <div class="listbox">
            <ul v-loading="loading">
                <li v-for="(item,index) in list">
                    <div class="messagetop" @click="gotoShow(item)"><span>[{{item.userName}}]</span> 与 <em>[{{item.memberName}}]</em>   <i>时间：{{item.createTime}}</i><u v-if="item.sign==1"></u></div>
                </li>
            </ul>
            <div class="page">
                <el-pagination
                background
                layout="prev, pager, next"
                :page-size="size"
                @current-change="changepage"
                @prev-click="prevpage"
                @next-click="nextpage"
                :current-page="page"
                :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
import userRequest from "@/api/user";
export default {
    data(){
        return{
            list:[],
            total:0,
            page:1,
            size:15,
            user:null,
            loading:false
        }
    },
    created(){
        this.user=JSON.parse(localStorage.getItem("userInfo"))
        this.getList()
    },
    methods:{
        gotoShow(row){
            this.$router.push({path:"/user/sendshow",query:{u:row.userId,m:row.memberId}})
        },
        getList(){
            this.loading=true
            userRequest.listByPid({pageNo:this.page,pageSize:this.size,id:this.user.id,isMember:false}).then(res=>{
                console.log(res)
                this.loading=false
                this.list=res.data.list
                this.total=res.data.total
            })
        },
        changepage(e){
            this.page=e
            this.getList()
        },
        prevpage(){
            this.page=this.page-1
            if(this.page<=0){
                this.page=1
            }
            this.getList()
        },
        nextpage(){
            this.page=this.page+1
            let maxnum=Mail.ceil(this.total/this.size)
            if(this.page>=maxnum){
                this.page=maxnum
            }
            this.getList()
        }
    }
}
</script>
<style scoped>
.listbox{ width: 1198px; box-sizing: border-box; margin:50px auto; background: #ffffff; padding:10px;}
.listbox ul li{ display: block; padding:0 10px;}
.listbox ul li:nth-child(even){ background: #fafafa;}
.messagetop{ height: 45px; line-height: 45px; cursor: pointer; position: relative;}
.messagetop span{color:#409eff; font-weight: bold;}
.messagetop em{ font-style: normal; color:#8e8e8e; font-weight: bold;}
.messagetop i{ font-style: normal; color:#999; float: right; }
.messagetop u{ display: block; position: absolute; width: 8px; height: 8px; border-radius: 4px; background: #f60; top:5px; right:-6px;}
.page{ text-align: center; padding:30px 0;}
.page /deep/ .el-pager li.active{color:#ffffff!important;}
</style>